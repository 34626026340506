import default_en from "./defaults/en.json";

// set api locale as default for unit tests
import current_locale from "@/docs/data.getty.edu/locale/en";
/**
 * deep merge of object
 *
 * @param {object} target
 * @param  {...any} sources
 * @returns
 */
function deepAssign(target, ...sources) {
  for (let source of sources) {
    for (let k in source) {
      let vs = source[k],
        vt = target[k];
      if (Object(vs) == vs && Object(vt) === vt) {
        target[k] = deepAssign(vt, vs);
        continue;
      }
      target[k] = source[k];
    }
  }
  return target;
}

import { locales } from "@thegetty-private/getty-ui";

let en = deepAssign({}, locales.en, default_en, current_locale);
export const messages = { en };
