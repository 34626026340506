import { createRouter, createWebHistory } from "vue-router";
import { getAppHost } from "@/helpers/configHelper";
const app_path = getAppHost();
import { fileExists } from "@/helpers/fileHelper";
import docList from "@/docs/data.getty.edu/doc_list.json";

let docRoutes = [];

// Here we're using the doc_list.json file to build routes for each API in the list,
// which allows us to avoid using parameters for the documentation IDs, which can have one or more "/" in them
// (e.g. "museum/collection").
docList.forEach((doc) => {
  let routeObject = {
    // We redirect from the documentation's "base url" to the "/docs/" route
    // (e.g. ...museum/collection -> ...museum/collection/docs/)
    path: "/" + doc.id,
    component: () => import("./views/content-page-layout.vue"),
    props: { id: doc.id },
    redirect: { name: doc.id + "_docs" },
    children: [
      // We always redirect from "/docs" to "/docs/" to make using relative links in Markdown
      // files more simple.
      {
        path: "docs",
        redirect: { name: doc.id + "_docs" },
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "docs/",
        name: doc.id + "_docs",
        pathToRegexpOptions: { strict: true },
        component: () => import("./components/pages/documentation/index.vue"),
        children: [
          {
            path: "revision",
            name: doc.id + "_revision",
            component: () => import("./components/pages/revisions/index.vue"),
          },
          // the "docs/:file" parameter route is used to display files other than "documentation.md",
          // for the given API
          {
            path: ":file(.*)*",
            name: doc.id + "_file",
            component: () =>
              import("./components/pages/documentation/index.vue"),
          },
        ],
      },
    ],
  };
  // check if revision file exists, if not remove it

  if (fileExists("revision_history.md", "/" + doc.id + "/") == false) {
    // index of docs/ route
    let mainRouteIndex = routeObject.children.findIndex(
      (route) => route.name == doc.id + "_docs"
    );
    // filter out /revision route
    let newChildren = routeObject.children[mainRouteIndex].children.filter(
      (route) => route.path != "revision"
    );
    routeObject.children[mainRouteIndex].children = newChildren;
  }
  docRoutes.push(routeObject);
});

const baseRoutes = [
  {
    path: "/",
    name: "home",
    component: () => import("./views/home-layout.vue"),
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () => import("./views/introduction-layout.vue"),
  },
  // This notFound route matches anything not in the router and presents the not-found
  // component. It is important that this be the last route in the router, because routes
  // are matched sequentially.
  {
    path: "*",
    name: "notFound",
    component: () => import("./views/not-found.vue"),
  },
];

export default createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  //defining the scrolling behavior when navigating using Vue router
  //for more options check: https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to) {
    if (!("tab" in to.query) && to.hash == "") {
      //scroll to the top of the page unless the URL contains a tab parameter
      return { top: 0 };
    }
  },
  routes: [
    {
      path: "/",
      component: () => import("./views/layout-base.vue"),
      children: [...docRoutes, ...baseRoutes],
    },
  ],
});
