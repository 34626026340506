const files = import.meta.glob("@/docs/**", { as: "raw" });
import { getAppHost } from "@/helpers/configHelper";
const app_path = getAppHost();

/**
 * Gets a file from the /docs data
 *
 * @param {string} file - the filename to look for
 * @param {string} path - the path to the file
 *
 * @returns {string} the file contents
 */
export async function getFile(file, path) {
  let key = path.replace("/docs/", "/");

  let name = file;
  if (file.endsWith("md")) {
    name = file.replace(".md", "");
  }
  if (path.endsWith(file) == false && path.endsWith(name) == false) {
    key += file;
  }
  key = key.replace("/revision/", "/");
  for (let filename of Object.keys(files)) {
    if (filename.indexOf(app_path) < 0) {
      continue;
    }
    if (filename.endsWith(key)) {
      return await files[filename]();
    }
    if (filename.endsWith(key + ".md")) {
      return await files[filename]();
    }
  }
  return undefined;
}

/**
 * Checks whether a file exists in the /docs area
 *
 * @param {string} file - the filename to look for
 * @param {string} path - the path to the file
 *
 * @returns {boolean}  whether the file exists
 */
export function fileExists(file, path) {
  let key = path.replace("/docs/", "/") + file;
  key = key.replace("/revision/", "/");
  for (let filename of Object.keys(files)) {
    if (filename.indexOf(app_path) < 0) {
      continue;
    }

    if (filename.endsWith(key)) {
      return true;
    }
  }
  return false;
}
